@import '../../assets/sass/styles.scss';

.Products {
  margin-top: 4rem;
  .columns {
    // justify-content: center;
    &.heading-columns {
      text-align: start;
      color: $primary;
      font-weight: 500;
      font-size: 2rem;
      font-family: Fazer;
      margin-bottom: 2rem;
    }
    img {
      max-width: 15vw;
    }
    .column {
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 769px) {
  .Products {
    margin: 2rem 2rem auto 2rem;
    .columns {
      // justify-content: center;

      &.is-centered {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-flow: wrap;
      }
      &.heading-columns {
        font-size: 1.5rem;
      }
      img {
        max-width: 65vw;
      }
      .column {
        padding: 8px 5px;
        align-items: center;
      }
    }
  }
}
